import { AxiosResponse } from 'axios'
import axios from '../instance'

interface SearchDashboardBackOffice  {
	startDate: string;
	endDate: string;
  }

export const getDashboard = async (): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/admin/dashboard')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getDashboardBackOffice = async (params: SearchDashboardBackOffice): Promise<any>  => {
	try {
		const response = await axios.get('/log-monitor/sort-dashboard',{params})
		return response.data 
	} catch (error) {
		return Promise.reject(error)
	}
}
