import { ref, Ref } from 'vue'
import dayjs from '@/utils/dayjs'

export type DisabledMonthRage = {
    tempStartDate: any,
    range3MonthFromTempStartDate: (date: Date) => boolean,
	beforeApil: (date: Date) => boolean,
    onCalendarChange: (date: Date[]) => void,
}

export const useDisableMonthRange = (defaultMonthRange = 3): DisabledMonthRage => {
	const tempStartDate = ref<Date|null>(null)

	const range3MonthFromTempStartDate = (date: Date) => {
		if (tempStartDate.value) {

			const currentDate = dayjs(date)
			const tempDate = dayjs(tempStartDate.value as any)
			const diffMonth = currentDate.diff(tempDate, 'month', true)
			const diffMonthFromTempDate = tempDate.diff(currentDate, 'month', true)

			if (diffMonth > defaultMonthRange || diffMonthFromTempDate > defaultMonthRange) {
				return true
			}

		}
		return dayjs(date).isAfter(dayjs(), 'day')
	}

	const beforeApil = (date: Date) => {
		const octorber = dayjs('2023-04-01')

		return dayjs(date).isBefore(octorber, 'day')
	}

	const onCalendarChange = (date: Date[]) => {
		tempStartDate.value = date[0] // startDate position index
	}

	return {
		range3MonthFromTempStartDate,
		beforeApil,
		onCalendarChange,
		tempStartDate,
	}
}