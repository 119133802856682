import { defineStore } from 'pinia'
import {
	State,
	SelectedShopId,
	ShopList,
	DashboardI,
	IBillPayment,
	IInboxImageDetail,
	IGetBillPayment,
	IAddressShopStore,
} from '@/pinia/shop/types'
import {
	getAddressList,
	getBillPayment as getBillPaymentApi,
	getDashboardShop,
	shopList,
} from '@/api/shop/shop.api'
import { DATE_SELECT } from '@/constants/dashborad'
import dayjs from 'dayjs'

export const useShopStore = defineStore('shop', {
	persist: {
		paths: ['selectedShopId', 'shopAddressList', 'dateSelect', 'datePick'],
	},
	state: (): State => ({
		selectedShopId: null,
		shops: [],
		dashboardData: null,
		billPayment: null,
		isDialogBillPayment: false,
		isDialogImage: false,
		isDashBoardNews: true,
		inboxImageDetail: null,
		shopAddressList: [] as IAddressShopStore[],
		dateSelect: DATE_SELECT.THIRTY_DAYS,
		datePick: [dayjs().startOf('day').subtract(1, 'month').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')],

		isAreadyShowDialog: null
	}),
	getters: {
		currentShop(state: State) {
			return state.shops.find((shop) => shop._id === state.selectedShopId)
		},
	},
	actions: {
		// old mutations
		updateSelectedShop(shopId: SelectedShopId) {
			this.selectedShopId = shopId
		},
		updateShopList(shops: ShopList) {
			this.shops = shops
		},
		updateDashboard(dashboardData: DashboardI) {
			this.dashboardData = dashboardData
		},
		updateBillPayment(billPayment: IBillPayment) {
			this.billPayment = billPayment
		},
		updateIsDialogBillPayment(isShow: boolean) {
			this.isDialogBillPayment = isShow
		},
		updateIsDialogInboxImage(isShow: boolean) {
			this.isDialogImage = isShow
			this.isDashBoardNews = isShow
		},
		setUpdateInboxImage(inboxImageDetail: IInboxImageDetail) {
			this.inboxImageDetail = inboxImageDetail
			this.updateIsDialogInboxImage(true)

			
		},
		updateDateSelect(select:string) {
			this.dateSelect = select
		},
		updateDatePick(dates:string[]) {
			this.datePick = dates
		},

		// actions
		async getAllShop() {
			try {
				const { data: shopResponse } = await shopList()
				this.updateShopList(shopResponse)
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getDashboardData(shopId: string) {
			try {
				const { data: dashboardResponse } = await getDashboardShop(
					shopId
				)
				this.updateDashboard(dashboardResponse)

			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getBillPayment(params: IGetBillPayment) {
			try {
				const { data: response } = await getBillPaymentApi(
					params.shopId
				)
				this.updateBillPayment(response)
				this.updateIsDialogBillPayment(params.isShowDialog as boolean)
			} catch (error) {
				console.log('NO BILL PAYMENT')
			}
		},
		async updateInboxImage(inboxImageDetail: IInboxImageDetail) {
			this.setUpdateInboxImage(inboxImageDetail)
			this.updateIsDialogInboxImage(true)
		},
		updateShopStoreAddress(address: IAddressShopStore[]) {
			this.shopAddressList = address
		},
		async actionFetchAndUpdateAddress(shopId: string) {
			await getAddressList(shopId as string)
				.then(({ data: { address } }) => {
					// อัพเดท persisted address ที่อยู่ใน store
					// เพื่อที่หน้า create order จะได้ไม่ต้อง fetch หรือ มี loading
					// ให้ใช้จากตัว persisted ได้เลย
					this.updateShopStoreAddress(address)
				})
		},
	
	},
})
