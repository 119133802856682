import dayjs from '@/utils/dayjs'

export interface MapThaiMonth {
    [key: string]: {
        long: string;
        short: string;
		value: string;
    }
}

export const mapThaiMonth: MapThaiMonth = {
	'01': {
		long: 'มกราคม',
		short: 'มค',
		value: dayjs('1', 'M').format('M'),
	},
	'02': {
		long: 'กุมภาพันธ์',
		short: 'กพ',
		value: dayjs('2', 'M').format('M'),
	},
	'03': {
		long: 'มีนาคม',
		short: 'มีนา',
		value: dayjs('3', 'M').format('M'),
	},
	'04': {
		long: 'เมษายน',
		short: 'เมษา',
		value: dayjs('4', 'M').format('M'),
	},
	'05': {
		long: 'พฤษภาคม',
		short: 'พค',
		value: dayjs('5', 'M').format('M'),
	},
	'06': {
		long: 'มิถุนายน',
		short: 'มย',
		value: dayjs('6', 'M').format('M'),
	},
	'07': {
		long: 'กรกฎาคม',
		short: 'กค',
		value: dayjs('7', 'M').format('M'),
	},
	'08': {
		long: 'สิงหาคม',
		short: 'สค',
		value: dayjs('8', 'M').format('M'),
	},
	'09': {
		long: 'กันยายน',
		short: 'กย',
		value: dayjs('9', 'M').format('M'),
	},
	'10': {
		long: 'ตุลาคม',
		short: 'ตค',
		value: dayjs('10', 'M').format('M'),
	},
	'11': {
		long: 'พฤศจิกายน',
		short: 'พย',
		value: dayjs('11', 'M').format('M'),
	},
	'12': {
		long: 'ธันวาคม',
		short: 'ธค',
		value: dayjs('12', 'M').format('M'),
	},
}
